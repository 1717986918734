import React, { useState, useEffect } from 'react'
import { ListName } from './functions-listas-select'
import AutoComplete from '../Functions/functions-autocomplete-ide-actual'
import loadImg from "../../../assets/img/load/load.gif";
import {
  Col,
  Modal,
  Row,
  Card,
  Form,
  Button,
  InputGroup,
} from '@themesberg/react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import debounce from 'lodash/debounce';

const StaticModal = (props) => {

  const [users, setUsers] = useState([]);
  const [modelosCab, setModCabezas] = useState([]);
  const [marcaPalos, setMarcaPalos] = useState([]);
  const [tiposCabezas, setTiposCabezas] = useState([]);
  const [modelosVarillas, setModelosVarillas] = useState([]);

  const [idEquActual, setIdEquActual] = useState(0)
  const [hierros, setHierros] = useState(0)
  const [numCabeza, setNumCabeza] = useState(0)
  const [putter, setPutter] = useState(0)
  const [archivos, setArchivos] = useState([])
  const [load, setLoad] = useState(false)
  const [idTipoPalo, setIdTipoPalo] = useState(0);

  const MarcaPalos = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/marca-varilla-get`)
      .then((response) => {
        setMarcaPalos(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const modeloCabezas = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/modelo-cabeza-get`)
      .then((response) => {
        setModCabezas(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const tipoCabezas = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/tipos-cabeza-get`)
      .then((response) => {
        setTiposCabezas(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const modelosVarilla = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/modelo-varilla-get`)
      .then((response) => {
        setModelosVarillas(response.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const id = props.id
  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' })

  const getIdEquActualId = (id) => {
    let defaultValues = {}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/identificacion-actual-id-get`, {
        params: { id },
      })
      .then((response) => {
        if (response != 'vacio') {
          // setIdVarillaHierrosAct(response.data[0].id)
          defaultValues.id_varilla_hierros = response.data[0].id
          defaultValues.id_marca = response.data[0].id_marca
          defaultValues.id_informe_tecnico = response.data[0].id_informe_tecnico
          defaultValues.id_modelo_cabeza = response.data[0].id_modelo_cabeza
          defaultValues.id_flex = response.data[0].id_flex
          defaultValues.loftCabeza = response.data[0].loftCabeza
          defaultValues.distancia = response.data[0].distancia
          defaultValues.angSalida = response.data[0].angSalida
          defaultValues.spin = response.data[0].spin
          defaultValues.factImpacto = response.data[0].factImpacto
          defaultValues.velBola = response.data[0].velBola
          defaultValues.velPalo = response.data[0].velPalo
          reset({ ...defaultValues })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const tipoPalo = (idTipoPalo) => {
    const id = parseInt(idTipoPalo, 10);
    setIdTipoPalo(id);
    if (idTipoPalo == 4) {
      setHierros(1)
    } else {
      setHierros(0)
    }
    if (idTipoPalo == 1 || idTipoPalo == 4 || idTipoPalo == 37) {
      setNumCabeza(0)
    } else {
      setNumCabeza(1)
    }
    if (idTipoPalo == 37) {
      setPutter(1)
    } else {
      setPutter(0)
    }
  }

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const subirArchivos = e => {
    setArchivos(e)
  }

  function imagenes(id) {
    const f = new FormData();
    if (archivos.length === 1) {
      f.append("images", archivos[0]);
    } else {
      for (let index = 0; index < archivos.length; index++) {
        f.append("images", archivos[index]);
      }
    }
    f.append("id", id)
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/img-equ-actual`, f)
      .then((res) => {
        Swal.fire({
          html: '<i>Process complete!</i>',
          icon: 'success',
          timer: 2000,
        })
        setShow(false)
        window.location.reload();
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // const actualizarID = (id) => {
  //   axios
  //     .put(`${process.env.REACT_APP_BASE_URL}/equipo-actual-del/${id}`)
  //     .then((res) => {
  //       Swal.fire({
  //         title: 'Eliminado!',
  //         icon: 'success',
  //         time: 1000,
  //       })
  //     })
  //     .catch((err) => {
  //       console.log(err)
  //     })
  // }


  const onSubmit = async (data) => {
    if (archivos.length === 0) {
      Swal.fire({
        html: '<i>Por favor seleccione mínimo una imagen.</i>',
        icon: 'warning',
      });
      setLoad(false);
      return;
    } else {
      // setLoad(true)
      // if (idEquActual) {
      //   axios
      //     .put(`${process.env.REACT_APP_BASE_URL}/upd-client`, {
      //       data: data,
      //     })
      //     .then((res) => {
      //       Swal.fire({
      //         html: '<i>Process complete!</i>',
      //         icon: 'success',
      //         timer: 2000,
      //       })
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // } else {
      //   axios
      //     .post(`${process.env.REACT_APP_BASE_URL}/identificacion-actual-ins`, {
      //       data: data,
      //     })
      //     .then((res) => {
      //       imagenes(res.data[0].id)
      //     })
      //     .catch((err) => {
      //       console.log(err)
      //     })
      // }
      setLoad(true);

      try {
        if (idEquActual) {
          await axios.put(`${process.env.REACT_APP_BASE_URL}/upd-client`, { data });
          Swal.fire({
            html: '<i>Process complete!</i>',
            icon: 'success',
            timer: 2000,
          });
        } else {
          const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/identificacion-actual-ins`, { data });
          imagenes(res.data[0].id);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
  return (
    <>
      <Button className="btn btn-next" onClick={handleShow}>
        Nuevo
      </Button>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>INFORME TÉCNICO</Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <input type="hidden" value={id} {...register('id_informe_tecnico')} />
            {idEquActual > 0 && <input type="hidden" value={idEquActual} {...register('id')} />}
            <Row>
              <h5 className="my-4">Información del equipo actual</h5>
              <Col md={4} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>Tipo de Palo</Form.Label>
                  <Form.Select defaultValue="0" {...register('id_tipo_palos')} onChange={(e) => { tipoPalo(e.target.value) }}>
                    <option value="0">Seleccione</option>
                    <ListName url="tipo-palos" />
                  </Form.Select>
                </Form.Group>
              </Col>
              {
                hierros > 0 &&
                <>
                  <Col md={4} className="mb-3">
                    <Form.Group>
                      <Form.Label>Desde</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Hasta"
                        {...register('desde')}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group>
                      <Form.Label>Hasta</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Desde"
                        {...register('hasta')}
                      />
                    </Form.Group>
                  </Col>
                </>
              }
              <Col md={4} className="mb-3">
                {/* <Form.Group id="marca-aplos">
                  <Form.Label>Marca Palos</Form.Label>
                  <input
                    id="marcaPalo"
                    type="text"
                    placeholder='Marca Palo'
                    className="form-control"
                    list="marcaPalos"
                    {...register('id_marcas')}
                    onChange={debounce(MarcaPalos, 300)}
                  onInput={e => setMarcaPalo(e.target.value)}
                  />
                  <datalist id="marcaPalos">
                    {marcaPalos.map((marcaP) => (
                      <option class="list" value={marcaP.name} key={marcaP.id} >
                        {marcaP.name}
                      </option>
                    ))}
                  </datalist>
                </Form.Group> */}
                <Form.Group id="marca-aplos">
                  <Form.Label>Marca Palos</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Marca Palos"
                    {...register('id_marcas')}
                  />
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="marca-aplos">
                  <Form.Label>Modelos Cabezas</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Modelos Cabezas"
                    {...register('id_modelo_cabeza')}
                  />
                </Form.Group>
                {/* <Form.Group id="marca-aplos">
                  <Form.Label>Modelos Cabezas</Form.Label>
                  <input
                    id="marcaCabeza"
                    type="text"
                    placeholder='Modelos Cabeza'
                    className="form-control"
                    list="modelosCabeza"
                    {...register('id_modelo_cabeza')}
                    onChange={debounce(modeloCabezas, 300)}
                  // onInput={e => setModelosCabeza(e.target.value)}
                  />
                  <datalist id="modelosCabeza">
                    {modelosCab.map((modCabeza) => (
                      <option class="list" value={modCabeza.name} key={modCabeza.id} >
                        {modCabeza.name}
                      </option>
                    ))}
                  </datalist>
                </Form.Group> */}
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="tipo-cabeza">
                  <Form.Label>Tipo Cabezas</Form.Label>
                  <Form.Select defaultValue="0" {...register('id_tipo_cabeza')}>
                    <option value="0">Seleccione</option>
                    <ListName url="tipos-cabeza-get" />
                  </Form.Select>
                </Form.Group>
                {/* <Form.Group id="marca-aplos">
                  <Form.Label>Tipo Cabezas</Form.Label>
                  <input
                    id="tipoCabeza"
                    type="text"
                    placeholder='Tipos Cabeza'
                    className="form-control"
                    list="tiposCabeza"
                    {...register('id_tipo_cabeza')}
                    onChange={debounce(tipoCabezas, 300)}
                  // onInput={e => setTipoCabeza(e.target.value)}
                  />
                  <datalist id="tiposCabeza">
                    {tiposCabezas.map((tipoCabeza) => (
                      <option class="list" value={tipoCabeza.name} key={tipoCabeza.id} >
                        {tipoCabeza.name}
                      </option>
                    ))}
                  </datalist>
                </Form.Group> */}

              </Col>
              {
                numCabeza > 0 &&
                <Col md={4} className="mb-3">
                  <Form.Group id="phone">
                    <Form.Label>Número de cabeza</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="número de cabeza"
                      {...register('numero_cabeza')}
                    />
                  </Form.Group>
                </Col>
              }
              {
                putter === 0 &&
                <>
                  {idTipoPalo !== 37 && (
                    <Col md={4} className="mb-3">
                      <Form.Group id="loftCabeza">
                        <Form.Label>Loft Cabeza</Form.Label>
                        <Form.Select defaultValue="0" {...register('loftCabeza')}>
                          <option value="">Sel. Loft</option>
                          {idTipoPalo === 1 && (
                            <>
                              <option value="8.0°">8.0°</option>
                              <option value="8.5°">8.5°</option>
                              <option value="9.0°">9.0°</option>
                              <option value="9.5°">9.5°</option>
                              <option value="10°">10°</option>
                              <option value="10.5°">10.5°</option>
                              <option value="11°">11°</option>
                              <option value="12°">12°</option>
                            </>
                          )}
                          {idTipoPalo === 2 && (
                            <>
                              <option value="W3">W3</option>
                              <option value="W5">W5</option>
                              <option value="W7">W7</option>
                              <option value="W9">W9</option>
                            </>
                          )}
                          {idTipoPalo === 3 && (
                            <>
                              <option value="HYB3">HYB3</option>
                              <option value="HYB4">HYB4</option>
                              <option value="HYB5">HYB5</option>
                              <option value="HYB6">HYB6</option>
                            </>
                          )}
                          {idTipoPalo === 4 && (
                            <>
                              <option value="26">26</option>
                              <option value="27">27</option>
                              <option value="28">28</option>
                              <option value="30.5">30.5</option>
                              <option value="33">33</option>
                              <option value="34">34</option>
                            </>
                          )}
                          {idTipoPalo === 5 && (
                            <>
                              <option value="PW43">PW 43</option>
                              <option value="PW48">PW 48</option>
                              <option value="AW50">AW 50</option>
                              <option value="AW52">AW 52</option>
                              <option value="SW54">SW 54</option>
                              <option value="SW56">SW 56</option>
                              <option value="LW58">LW 58</option>
                              <option value="LW60">LW 60</option>
                              <option value="LW62">LW 62</option>
                              <option value="LW64">LW 64</option>
                            </>
                          )}
                          {idTipoPalo === 6 && (
                            <>
                              <option value="ID2">ID 2</option>
                              <option value="ID3">ID 3</option>
                              <option value="ID4">ID 4</option>
                              <option value="ID5">ID 5</option>
                            </>
                          )}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  )}
                  {/* <Col md={4} className="mb-3">
                    <Form.Group id="loftCabeza">
                      <Form.Label>Loft Cabeza</Form.Label>
                      <Form.Select
                        defaultValue="0"
                        {...register('loftCabeza')}
                      >
                        <option value="">Sel. Loft</option>
                        <option value="ID 2">ID 2</option>
                        <option value="ID 3">ID 3</option>
                        <option value="ID 4">ID 4</option>
                        <option value="ID 5">ID 5</option>
                      </Form.Select>
                    </Form.Group>
                  </Col> */}
                  <Col md={4} className="mb-3">
                    <Form.Group id="modelo-varilla">
                      <Form.Label>Modelo Varilla</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Modelo Varilla"
                        {...register('id_palo')}
                      />
                    </Form.Group>
                    {/* <Form.Group id="marca-aplos">
                      <Form.Label>Modelo Varilla</Form.Label>
                      <input
                        id="modeloVarilla"
                        type="text"
                        placeholder='Modelos Varilla'
                        className="form-control"
                        list="modelosVarilla"
                        {...register('id_palo')}
                        onChange={debounce(modelosVarilla, 300)}
                      // onInput={e => setModeloVarilla(e.target.value)}
                      />
                      <datalist id="modelosVarilla">
                        {modelosVarillas.map((modVarilla) => (
                          <option class="list" value={modVarilla.name} key={modVarilla.id} >
                            {modVarilla.name}
                          </option>
                        ))}
                      </datalist>
                    </Form.Group> */}
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="fabrica-varilla">
                      <Form.Label>Fabrica Varilla</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Fabrica Varilla"
                        {...register('fabrica')}
                      />
                    </Form.Group>
                    {/* <Form.Group id="fabrica">
                      <Form.Label>Fabrica Varilla</Form.Label>
                      <Form.Select defaultValue="0" {...register('fabrica')}>
                        <ListName url="fabricas-get" />
                      </Form.Select>
                    </Form.Group> */}
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="material">
                      <Form.Label>Material</Form.Label>
                      <Form.Select defaultValue="0" {...register('material')}>
                        <option value="">Seleccione</option>
                        <ListName url="material-get" />
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="flex">
                      <Form.Label>Flex</Form.Label>
                      <Form.Select defaultValue="0" {...register('flex')}>
                        <option value="">Seleccione</option>
                        <ListName url="flexs-get" />
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={4} className="mb-3">
                    <Form.Group id="peso">
                      <Form.Label>Peso</Form.Label>
                      <Form.Select
                        defaultValue="0"
                        {...register('peso')}
                      >
                        <option value="">Sel. peso</option>
                        <option value="30 gr">30 gr</option>
                        <option value="40 gr">40 gr</option>
                        <option value="50 gr">50 gr</option>
                        <option value="60 gr">60 gr</option>
                        <option value="70 gr">70 gr</option>
                        <option value="80 gr">80 gr</option>
                        <option value="90 gr">90 gr</option>
                        <option value="100 gr">100 gr</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </>
              }
              <Col md={4} className="mb-3">
                <Form.Group id="gender">
                  <Form.Label>Estado del equipo</Form.Label>
                  <Form.Select defaultValue="0" {...register('estadoEquipo')}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="precio">
                  <Form.Label>Precio</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Precio"
                    {...register('precio')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="precio">
                  <Form.Label>Imagenes</Form.Label>
                  <Form.Control
                    type="file"
                    name="files"
                    multiple
                    onChange={(e) => subirArchivos(e.target.files)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <div className="mt-3">
              {idEquActual > 0 ? (
                <Button variant="success" type="submit" disabled={load}>
                  Actualizar
                </Button>
              ) : (
                <Button variant="primary" type="submit" disabled={load}>
                  Guardar
                </Button>
              )}
              {load && <img src={loadImg} alt="Loading..." width="100px" />}
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default StaticModal
